import React, { useState } from "react";
import { Link } from "react-router-dom";
import iconAllLaunches from "../icons/all-launch.png";
import iconFairLaunches from "../icons/fair-launch.png";
import iconTokerCreator from "../icons/token-creator.png";
import iconMyContributions from "../icons/my-contributions.png";
import iconTutorials from "../icons/tutorials.png";
import iconDocuments from "../icons/documents.png";
// import iconCompetitions from "../icons/competitions.png";
import iconTelegram from "../icons/telegram2.png";
import iconX from "../icons/x.png";
import classnames from "classnames";

const LeftBar = ({ isExpanded, onHamburgerClick }) => {
  const [showLinks] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  let currentPath = window.location.pathname;
  const textClass = classnames("left-bar-text", {
    "left-bar-text-active": isExpanded || isHovered,
  });

  return (
    <div
      className={`left-bar ${isExpanded ? "left-bar-expanded" : ""}`}
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
      onClick={onHamburgerClick}
    >
      <ul className="left-bar-links">
        {(isHovered || showLinks) && (
          <>
            <li>
              <Link to="/AllLaunches" className="left-bar-link">
                <img
                  src={iconAllLaunches}
                  alt="All Launches"
                  className={
                    currentPath === "/AllLaunches"
                      ? "left-bar-icon-selected"
                      : "left-bar-icon"
                  }
                />
                <span
                  className={
                    currentPath === "/AllLaunches"
                      ? "listSelected " + textClass
                      : "listUnselected " + textClass
                  }
                >
                  All&nbsp;Launches
                </span>
              </Link>
            </li>
            <li>
              <Link to="/FairLaunch" className="left-bar-link">
                <img
                  src={iconFairLaunches}
                  alt="Fair Launches"
                  className={
                    currentPath === "/FairLaunch"
                      ? "left-bar-icon-selected"
                      : "left-bar-icon"
                  }
                />
                <span
                  className={
                    currentPath === "/FairLaunch"
                      ? "listSelected " + textClass
                      : "listUnselected " + textClass
                  }
                >
                  Fair&nbsp;Launch
                </span>
              </Link>
            </li>
            <li>
              <Link to="/" className="left-bar-link">
                <img
                  src={iconTokerCreator}
                  alt="Token Creator"
                  className={
                    currentPath === "/"
                      ? "left-bar-icon-selected"
                      : "left-bar-icon"
                  }
                />
                <span
                  className={
                    currentPath === "/"
                      ? "listSelected " + textClass
                      : "listUnselected " + textClass
                  }
                >
                  Token&nbsp;Creator
                </span>
              </Link>
            </li>
            <li>
              <Link to="/MyContributions" className="left-bar-link">
                <img
                  src={iconMyContributions}
                  alt="My Contributions"
                  className={
                    currentPath === "/MyContributions"
                      ? "left-bar-icon-selected"
                      : "left-bar-icon"
                  }
                />
                <span
                  className={
                    currentPath === "/MyContributions"
                      ? "listSelected " + textClass
                      : "listUnselected " + textClass
                  }
                >
                  My&nbsp;Contributions
                </span>
              </Link>
            </li>
            <li>
              <div className="left-bar-link">
                <img
                  src={iconTutorials}
                  alt="Tutorials"
                  className="left-bar-icon"
                />
                <span className={`${textClass} gray`}>Tutorials</span>
              </div>
            </li>
            <li>
              <div className="left-bar-link">
                <img
                  src={iconDocuments}
                  alt="Documents"
                  className="left-bar-icon"
                />
                <span className={`${textClass} gray`}>Documents</span>
              </div>
            </li>
            {/* <li>
              <div className="left-bar-link">
                <img
                  src={iconCompetitions}
                  alt="Competitions"
                  className="left-bar-icon"
                />
                <span className={`${textClass} gray`}>Competitions</span>
              </div>
            </li> */}
            <div className="left-bar-hr" />
            <li>
              <a
                href="https://t.me/Makerscom"
                target="_blank"
                rel="noreferrer"
                className="left-bar-link"
              >
                <img
                  src={iconTelegram}
                  alt="Telegram"
                  className="left-bar-icon"
                />
                <span className={`${textClass} listUnselected`}>Telegram</span>
              </a>
            </li>
            <li>
              <a
                href="https://x.com/makers_ethics/status/1815501622979756522?s=46&t=8r-aku7X9CTdJUhUOcPngA"
                target="_blank"
                rel="noreferrer"
                className="left-bar-link"
              >
                <img src={iconX} alt="X" className="left-bar-icon" />
                <span className={`${textClass} listUnselected`}>X.com</span>
              </a>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default LeftBar;
