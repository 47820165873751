/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import '../App.css'
import FairLaunchFactoryAbi from '../config/FairLaunchFactoryAbi.json'
import FairLaunchAbi from '../config/FairLaunchAbi.json'
import '../styles/MainContainer.css'
import { readContract } from '@wagmi/core'
import MoonHeaderLink from '../components/MoonHeaderLink'
import { useWeb3Modal } from '@web3modal/react'
import styles from '../styles/container/Container.module.scss'
import MoonImage from '../icons/new-moon.svg'
import Footer from '../components/Footer'
import TopBar from '../components/TopBar'

const App = () => {
  const { address, isConnected } = useAccount()
  const { chain } = useNetwork()

  let [loading, setLoading] = useState(false)

  const [firstConnect, setFirstConnect] = useState(false)
  const { open } = useWeb3Modal()

  const onConnect = async () => {
    await open()
  }

  const onConnectWallet = async () => {
    await open()
    setFirstConnect(true)
  }

  useEffect(() => {
    const reloadWindow = async () => {
      try {
        window.location.reload()
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true && firstConnect === true) reloadWindow()
  }, [isConnected, firstConnect])

  const { switchNetwork } = useSwitchNetwork()
  useEffect(() => {
    const switchChain = async () => {
      try {
        switchNetwork?.(8453)
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true) {
      if (chain?.id !== 8453) switchChain()
    }
  }, [isConnected, chain?.id, switchNetwork])

  useEffect(() => {
    if (loading === true) {
      setTimeout(function () {
        setLoading(false)
      }, 3000)
    }
  }, [loading])

  return (
    <div>
      <div className="GlobalContainer">
        <div style={{ zIndex: 1 }}>
          <TopBar />
          <div className="navBar">
          </div>
          <div className="headerMargin" />
          <div className="MainDashboard" style={{ height: '40vh' }}>
            <>
              <section>
                <section>
                  {/* <p className="ContractContentTextTitle h1">
                    The Base Network's Fair-Launchpad
                  </p> */}
                  <p style={{ textAlign: 'center' }}>
                    <a href="/FairLaunch" className="create-token-button">
                      Create&nbsp;Fair&nbsp;Launch
                    </a>
                  </p>
                  <br />
                </section>
              </section>
            </>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default App
