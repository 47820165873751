import React, { useState, useEffect } from 'react'
import sortBy from 'lodash/sortBy'
// import rocketImage from '../icons/rocket.svg'
import iconHamburger from '../icons/hamburger.svg'
import LeftBar from './LeftBar'
import { readContract } from '@wagmi/core'
import { useAccount, useSwitchNetwork, useNetwork } from 'wagmi'
import MultiCallAbi from '../config/MultiCallAbi.json'
import { useWeb3Modal } from '@web3modal/react'
import MoonHeaderLink from '../components/MoonHeaderLink'

const TopBar = () => {
  const [isExpanded, setIsExpanded] = useState(false)
  const multicallAddress = '0xeeCa36fB4f5291810076bF1F23dd4d14b8AB364e'
  const [sortedList, setSortedList] = useState([])
  const [firstConnect, setFirstConnect] = useState(false)
  const { address, isConnected } = useAccount()
  const { chain } = useNetwork()
  const handleHamburgerClick = () => {
    setIsExpanded(!isExpanded)
  }

  const onConnect = async () => {
    await open();
  };

  useEffect(() => {
    // Add event listener for window resize
    const handleResize = () => {
      if (window.innerWidth >= 640) {
        setIsExpanded(false)
      }
    }

    window.addEventListener('resize', handleResize)
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleDocumentClick = event => {
    const leftBarElement = document.querySelector('.left-bar')
    const hamburgerMenuElement = document.querySelector('.left-bar-menu')

    // Check if the click is outside the left-bar
    if (
      !leftBarElement.contains(event.target) &&
      event.target !== hamburgerMenuElement
    ) {
      setIsExpanded(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [])

  useEffect(() => {
    const FetchData = async () => {
      try {

        const getTrendingInfo = await readContract({
          address: multicallAddress,
          abi: MultiCallAbi,
          functionName: 'presaleTrending'
        })
        if (Number(getTrendingInfo[0]) > 0) {
          let presaleListsDatas = [];
          let presaleData;
          let trendingInfos = [];
          for (let i = 0; i < getTrendingInfo[0].length; i++) {
            if (Number(getTrendingInfo[0][i]) > 0) {
              presaleData = { name: getTrendingInfo[1][i], address: getTrendingInfo[2][i], trending: Number(getTrendingInfo[0][i]) };
              trendingInfos.push(Number(getTrendingInfo))
              presaleListsDatas.push(presaleData);
            }
          }
          presaleData = { name: getTrendingInfo[1], address: getTrendingInfo[2], trending: Number(getTrendingInfo[0]) };
          trendingInfos.push(Number(getTrendingInfo))
          presaleListsDatas.push(presaleData);
          const sortedList = sortBy(presaleListsDatas, ['trending']).reverse();
          setSortedList(sortedList)
          trendingInfos.sort(function (a, b) { return a - b });
        }
      } catch (e) {
        console.error(e)
      }
    }
    if (address) {
      FetchData()
    }
  }, [address])

  const { open } = useWeb3Modal()

  const onConnectWallet = async () => {
    await open()
    setFirstConnect(true)
  }

  useEffect(() => {
    const reloadWindow = async () => {
      try {
        window.location.reload()
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true && firstConnect === true) reloadWindow()
  }, [isConnected, firstConnect])

  const { isLoading, pendingChainId, switchNetwork } = useSwitchNetwork()

  return (
    <>
      <LeftBar
        isExpanded={isExpanded}
        onHamburgerClick={handleHamburgerClick}
      />
      <div className="top-bar">
        <MoonHeaderLink className="tokenHeader" />
        <div className='tokenHeader1'>
          <div className='top-trending'>
            <img
              src={iconHamburger}
              alt="Menu"
              className="left-bar-menu"
              onClick={handleHamburgerClick}
            />
            <span className="top-bar-text-logo">DEXMAKER</span>
            {/* {sortedList.map(({ name, address, trending }, i) => (
              <a className="top-bar-text" href={'/buy/?address=' + address} target='_blank' rel="noreferrer">#{name}</a>
            ))} */}
          </div>
          <div className="navConnectButtonBox">
            {!isConnected ? (
              <>
                <button
                  className="navConnectButton"
                  type="submit"
                  onClick={() => {
                    onConnectWallet()
                  }}
                >
                  Connect<span className="navWallet"> Wallet</span>
                </button>
              </>
            ) : (
              <section>
                <div className="ChainGroupButton">
                  {chain?.id === 8453 ? (
                    <button
                      className="navConnectButton"
                      type="submit"
                      onClick={() => {
                        onConnect()
                      }}
                      style={{ fontSize: '20px' }}
                    >
                      {address.slice(0, 4) + '...' + address.slice(-4)}
                    </button>
                  ) : (
                    <button
                      className="navConnectButton"
                      type="submit"
                      onClick={() => switchNetwork?.(8453)}
                    >
                      {'To Base'}
                      {isLoading && pendingChainId === 8453 && ''}
                    </button>
                  )}
                </div>
              </section>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default TopBar
