import React from 'react'
import PropTypes from 'prop-types'
import WebsiteIcon from '../icons/website.png'
import TelegramIcon from '../icons/telegram.png'
import TwitterIcon from '../icons/x-icon.svg'
import DiscordIcon from '../icons/discord-icon.svg'
import { Link } from 'react-router-dom'

const SocialSection = ({ website, telegram, twitter, discord }) => (
    <div
        className="social-section"
        style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}
    >
        {/* Website */}
        {website && (
            <a href={website} target="_blank" rel="noopener noreferrer">
                <img src={WebsiteIcon} alt="Website" className="social-icon" />
            </a>
        )}

        {/* Telegram */}
        {telegram && (
            <a href={`${telegram}`} target="_blank" rel="noopener noreferrer">
                <img
                    src={TelegramIcon}
                    alt="Telegram"
                    className="social-icon"
                />
            </a>
        )}

        {/* Twitter */}
        {twitter && (
            <a href={`${twitter}`} target="_blank" rel="noopener noreferrer">
                <img src={TwitterIcon} alt="Twitter" className="social-icon" />
            </a>
        )}

        {/* Discord */}
        {discord && (
            <a href={`${discord}`} target="_blank" rel="noopener noreferrer">
                <img src={DiscordIcon} alt="Discord" className="social-icon" />
            </a>
        )}
    </div>
)

const CoinCard = ({
    tokenAddress,
    tokenName,
    LogoUrl,
    BannerUrl,
    LpBurnAmount,
    Website,
    Telegram,
    Discord,
    Twitter
}) => {
    const badges = [
        {
            name: 'Rug-Proof',
            className: 'launchpad-badge-rug-proof',
            condition: true
        }
    ]
    const link = `/token/?address=${tokenAddress}`
    const bannerUrl = 'url("' + BannerUrl + '")'

    return (
        <div
            className="launchpad-card"
            style={{
                height: '450px'
            }}
        >
            <div
                style={{
                    backgroundImage: bannerUrl,
                    backgroundSize: '100% 120px',
                    backgroundRepeat: "no-repeat",
                }}
            >
                <div
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                ></div>

                <div
                    className="launchpad-eth-logo-container"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '20px'
                    }}
                >
                    {LogoUrl}
                </div>

                <p className="launchpad-token-name">{tokenName}</p>

                <div
                    className="launchpad-badges-row"
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center'
                    }}
                >
                    {badges.map((badge, index) =>
                        LpBurnAmount ? (
                            <div
                                key={index}
                                className={badge.className}
                                style={{
                                    display: badge.condition ? 'flex' : 'none'
                                }}
                            >
                                <span className="launchpad-badge-text">
                                    {badge.name}
                                </span>
                            </div>
                        ) : (
                            <div
                                className={badge.className}
                                style={{ background: 'transparent' }}
                            ></div>
                        )
                    )}
                </div>

                <SocialSection
                    website={Website}
                    telegram={Telegram}
                    twitter={Twitter}
                    discord={Discord}
                />

                <p className="lauchpad-cap-limit" style={{ marginTop: '20px' }}>
                    Stealth Launch
                </p>
                {LpBurnAmount ? (
                    <p
                        className="launchpda-cap-type"
                        style={{ fontSize: '16px' }}
                    >
                        Automatic Burn 🔥
                    </p>
                ) : (
                    <p
                        className="launchpda-cap-type"
                        style={{ height: '16px' }}
                    ></p>
                )}

                <div className="launchpad-hr">
                    <hr />
                </div>

                <div className="launchpad-bottom-row">
                    <Link
                        className="launchpad-details-button"
                        to={link}
                        style={{ width: '70%', margin: 'auto' }}
                    >
                        <span className="launchpad-details-button-text">
                            Details
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

CoinCard.propTypes = {
    tokenName: PropTypes.string.isRequired,
    tokenAddress: PropTypes.string.isRequired
}

CoinCard.defaultProps = {
    RugProof: false
}

export default CoinCard
