/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import '../App.css'
import FairLaunchAbi from '../config/FairLaunchAbi.json'
import '../styles/MainContainer.css'
import Input from '../components/Input.tsx'
import { readContract, writeContract } from '@wagmi/core'
import { waitForTransaction } from '@wagmi/core'
import Web3 from 'web3'
import { useWeb3Modal } from '@web3modal/react'
import { toast } from 'react-hot-toast'
import Footer from '../components/Footer'
import ClaimCard from '../components/ClaimCard.jsx'
import { ReactComponent as StatusSaleLive } from '../icons/status-sale-live.svg'
import { ReactComponent as StatusSaleClosed } from '../icons/status-closed.svg'
import { ReactComponent as StatusUpcoming } from '../icons/status-upcoming.svg'
import BigCountdown from '../components/BigCountdown'
import TopBar from '../components/TopBar'
import ClipLoader from 'react-spinners/ClipLoader'
import { useQueryParam, StringParam } from 'use-query-params'
import { createPublicClient, http } from 'viem'
import { base } from 'viem/chains'

const publicClient = createPublicClient({
  chain: base,
  transport: http()
})

const App = () => {
  let [presaleAddress] = useQueryParam('address', StringParam)
  const BASE_PROVIDER_URL = 'https://base-pokt.nodies.app'
  let BaseWeb3 = new Web3(new Web3.providers.HttpProvider(BASE_PROVIDER_URL))
  const { address, isConnected } = useAccount()
  const { chain } = useNetwork()
  const [tokenName, setTokenName] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [tokenAddress, setTokenAddress] = useState('')
  const [tokenLogo, setTokenLogo] = useState('')
  const [tokenBanner, setTokenBanner] = useState('')
  const [softCap, setSoftCap] = useState('')
  const [tokenSupply, setTokenSupply] = useState(100)
  const [liquidity, setLiquidiity] = useState(0)
  const [tokens, setTokens] = useState(0)
  const [startTime, setStartTime] = useState(new Date().toLocaleString())
  const [endTime, setEndTime] = useState(new Date().toLocaleString())
  const [depositedAmount, setDepositedAmount] = useState(0)
  const [maxBuyAmount, setMaxBuyAmount] = useState(0)
  const [tokenDescription, setTokenDescription] = useState('')
  const [saleLive, setSaleLive] = useState('')
  const [allIns, setAllIn] = useState('')
  const [state, setState] = useState()
  const [tokenAmount, setAmount] = useState(0)
  let [accountBalance, setAccountBalance] = useState(0)
  const [depositUserAmount, setDepositUserAmount] = useState()
  const [investors, setInvestors] = useState()
  const [refundable, setRefundable] = useState()
  const [claimable, setClaimable] = useState()
  const [owner, setOwner] = useState()
  let [loading, setLoading] = useState(false)
  let [creating, setCreating] = useState(false)
  const [firstConnect, setFirstConnect] = useState(false)

  const [website, setWebsite] = useState()
  const [twitter, setTwitter] = useState()
  const [telegram, setTelegram] = useState()
  const [discord, setDiscord] = useState()
  const [claimableAmount, setClaimableAmount] = useState()
  const [withdrawAmount, setWithdrawAmount] = useState()

  useEffect(() => {
    const reloadWindow = async () => {
      try {
        window.location.reload()
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true && firstConnect === true) reloadWindow()
  }, [isConnected, firstConnect])

  const { switchNetwork } = useSwitchNetwork()
  useEffect(() => {
    const switchChain = async () => {
      try {
        switchNetwork?.(8453)
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true) {
      if (chain?.id !== 8453) switchChain()
    }
  }, [isConnected, chain?.id, switchNetwork])

  useEffect(() => {
    if (loading === true) {
      setTimeout(function () {
        setLoading(false)
      }, 3000)
    }
  }, [loading])

  const onTokenDeposit = async () => {
    try {
      setCreating(true)
      let deposit
      deposit = await writeContract({
        address: presaleAddress,
        abi: FairLaunchAbi,
        functionName: 'contributeETH',
        value: BaseWeb3.utils.toWei(String(tokenAmount), 'ether')
      })
      const depositData = await waitForTransaction({
        hash: deposit.hash
      })
      console.log('depositData', depositData)
      setTimeout(function () {
        setCreating(false)
      }, 3000)
      toast.success(`Successfully ${tokenAmount} ETH deposited`)
    } catch (err) {
      toast.error(
        'There is a problem with your ETH deposit. Please try again later'
      )
      setCreating(false)
    }
  }

  const onWithdraw = async () => {
    try {
      setCreating(true)
      let withdraw
      withdraw = await writeContract({
        address: presaleAddress,
        abi: FairLaunchAbi,
        functionName: 'withdrawContribute'
      })
      const withdrawData = await waitForTransaction({
        hash: withdraw.hash
      })
      console.log('withdrawData', withdrawData)
      setTimeout(function () {
        setCreating(false)
      }, 3000)
      toast.success(`Successfully withdrawed`)
    } catch (err) {
      toast.error(
        'There is a problem with your ETH withdraw. Please try again later'
      )
      setCreating(false)
    }
  }

  const onTokenEmergency = async () => {
    try {
      setCreating(true)
      let withdraw
      withdraw = await writeContract({
        address: presaleAddress,
        abi: FairLaunchAbi,
        functionName: 'emergencyWithdraw'
      })
      const withdrawData = await waitForTransaction({
        hash: withdraw.hash
      })
      console.log('withdrawData', withdrawData)
      setTimeout(function () {
        setCreating(false)
      }, 3000)
      toast.success(`Successfully withdrawed`)
    } catch (err) {
      toast.error(
        'There is a problem with your ETH withdraw. Please try again later'
      )
      setCreating(false)
    }
  }

  const onClaim = async () => {
    try {
      setCreating(true)
      let claim
      claim = await writeContract({
        address: presaleAddress,
        abi: FairLaunchAbi,
        functionName: 'claim'
      })
      const claimData = await waitForTransaction({
        hash: claim.hash
      })
      console.log('claimData', claimData)
      setTimeout(function () {
        setCreating(false)
      }, 3000)
      toast.success(`Successfully Claimed`)
    } catch (err) {
      toast.error('There is a problem with your claim. Please try again later')
      setCreating(false)
    }
  }

  const onCancel = async () => {
    try {
      setCreating(true)
      let cancel
      cancel = await writeContract({
        address: presaleAddress,
        abi: FairLaunchAbi,
        functionName: 'cancel'
      })
      const cancelData = await waitForTransaction({
        hash: cancel.hash
      })
      console.log('cancelData', cancelData)
      setTimeout(function () {
        setCreating(false)
      }, 3000)
      toast.success(`Successfully Cancelled`)
    } catch (err) {
      toast.error(
        'There is a problem with your setting. Please try again later'
      )
      setCreating(false)
    }
  }

  const onFinalize = async () => {
    try {
      setCreating(true)
      let finalize
      finalize = await writeContract({
        address: presaleAddress,
        abi: FairLaunchAbi,
        functionName: 'finalize'
      })
      const finalizeData = await waitForTransaction({
        hash: finalize.hash
      })
      console.log('finalizeData', finalizeData)
      setTimeout(function () {
        setCreating(false)
      }, 3000)
      toast.success(`Successfully Finalized`)
    } catch (err) {
      toast.error(
        'There is a problem with your setting. Please try again later'
      )
      setCreating(false)
    }
  }

  useEffect(() => {
    const FetchData = async () => {
      try {
        const PresaleInfo = await publicClient.readContract({
          address: presaleAddress,
          abi: FairLaunchAbi,
          functionName: 'getPresaleInfo'
        })
        let state = 0
        if (PresaleInfo[0] === true) {
          state = 1
        } else {
          const presaleEndTimestamp = Number(PresaleInfo[1])
          if (Number(presaleEndTimestamp) < new Date().getTime() / 1000) {
            state = 2
          } else {
            state = 0
          }
        }
        setState(state)
        setTokenName(PresaleInfo[6])
        setTokenSymbol(PresaleInfo[10])
        setTokenAddress(PresaleInfo[9])
        setTokenLogo(
          'https://dexmaker.zapto.org:13001/uploads/' + PresaleInfo[7]
        )
        setTokenBanner(
          'https://dexmaker.zapto.org:13001/uploads/' + PresaleInfo[8]
        )
        setSoftCap(Number(PresaleInfo[2]) / 10 ** 18)
        let allIn
        const totalTokens = Number(PresaleInfo[12])
        setTokens(Number(PresaleInfo[12]))
        if (Number(totalTokens) === Number(PresaleInfo[12])) {
          allIn = true
        } else {
          allIn = false
        }
        setAllIn(allIn)
        setLiquidiity(Number(PresaleInfo[4]))
        setStartTime(new Date(Number(PresaleInfo[5]) * 1000).toLocaleString())
        setEndTime(new Date(Number(PresaleInfo[1]) * 1000).toLocaleString())
        setDepositedAmount(Number(PresaleInfo[3]) / 10 ** 18)
        setMaxBuyAmount(Number(PresaleInfo[11]) / 10 ** 18)
        setSaleLive(PresaleInfo[0])
        if (address) {
          accountBalance = await BaseWeb3.eth.getBalance(address)
          accountBalance = BaseWeb3.utils.fromWei(accountBalance, 'ether')
          setAccountBalance(accountBalance)
          const PresaleOtherInfo = await readContract({
            address: presaleAddress,
            abi: FairLaunchAbi,
            functionName: 'getPresaleOtherInfo',
            args: [address]
          })
          setTokenDescription(PresaleOtherInfo[0])
          const presaleDepositedAmount = Number(PresaleOtherInfo[1])
          setDepositUserAmount(Number(presaleDepositedAmount) / 10 ** 18)
          setInvestors(Number(PresaleOtherInfo[2]))
          setRefundable(PresaleOtherInfo[3])
          setClaimable(PresaleOtherInfo[4])
          setOwner(PresaleOtherInfo[5])

          const PresaleSiteInfo = await readContract({
            address: presaleAddress,
            abi: FairLaunchAbi,
            functionName: 'getPresaleSiteInfo'
          })
          setWebsite(PresaleSiteInfo[0])
          setTwitter(PresaleSiteInfo[1])
          setTelegram(PresaleSiteInfo[2])
          setDiscord(PresaleSiteInfo[3])
          if (
            presaleDepositedAmount > 0 &&
            PresaleOtherInfo[4] === true &&
            state === 2
          ) {
            const vestedAmount = await readContract({
              address: presaleAddress,
              abi: FairLaunchAbi,
              functionName: 'getVestedAmount',
              args: [address]
            })
            setClaimableAmount(Number(vestedAmount) / 10 ** 18)
            const withrawedAmount = await readContract({
              address: presaleAddress,
              abi: FairLaunchAbi,
              functionName: 'claimed',
              args: [address]
            })
            setWithdrawAmount(Number(withrawedAmount) / 10 ** 18)
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
    if (creating === false) {
      FetchData()
    }
  }, [chain?.id, creating, presaleAddress])

  const statusIcons = {
    0: <StatusUpcoming />,
    1: <StatusSaleLive />,
    2: <StatusSaleClosed />
  }

  const statusClasses = {
    0: 'launchpad-status-upcoming',
    1: 'launchpad-status-sale-live',
    2: 'launchpad-status-closed'
  }
  let progress = (depositedAmount * 100) / softCap
  let currentRaiseAmount = progress
  if (progress > 100) {
    progress = 100
  }
  const CapLimit = softCap
  const LaunchStatus =
    saleLive === true
      ? 'Sale Ends'
      : state === 2
      ? 'Sale Ended'
      : 'Sale Start In'
  const ButtonText =
    saleLive === true ? (state === 1 ? 'Buy with ETH' : 'Claim') : 'Please wait'
  const maxBuy = maxBuyAmount
  const currentRaise =
    depositedAmount + '(' + currentRaiseAmount.toFixed(3) + '%)'

  const setMaxAmount = async () => {
    if (accountBalance > 0) accountBalance = accountBalance - 0.0001
    if (accountBalance > softCap / 10) {
      setAmount(softCap / 10)
    } else {
      setAmount(accountBalance)
    }
    console.log('STATE', state)
  }
  
  return (
    <div>
      <div className="GlobalContainer">
        {
          <div style={{ zIndex: 1 }}>
            <TopBar />
            <div className="navBar">
            </div>
            <div className="headerMargin" />
            <h1 className="h1 title center">Get Token</h1>
            <br/>
            <br/>
            <div className="MainDashboard ResponsiveFlexLayout">
              {/* <div>
                <iframe
                  src={`https://dexscreener.com/base/${tokenAddress}?embed=1&trades=0&info=0&theme=light`}
                  className="chart"
                  title="chart"
                />
              </div> */}

              <section className="ClaimLeftColumn">
                <p className="avoid-scam avoid-scam-text">
                  Avoid scam links! Make sure the website is <b>dexmaker.io</b>
                </p>

                <div className="swap-card">
                  {state !== 2 ? (
                    <>
                      <p
                        className="claim-text"
                        style={{
                          fontSize: '18px',
                          margin: 'auto',
                          width: '100%',
                          textAlign: 'center'
                        }}
                      >
                        {LaunchStatus}
                      </p>
                      <BigCountdown
                        futureDate={
                          saleLive
                            ? endTime
                            : state === 2
                            ? new Date().toLocaleString()
                            : startTime
                        }
                      />
                    </>
                  ) : (
                    <p
                      className="claim-text"
                      style={{
                        fontSize: '14px',
                        margin: 'auto',
                        width: '100%',
                        textAlign: 'center'
                      }}
                    >
                      {LaunchStatus}
                    </p>
                  )}

                  <div className="launchpad-progress-container">
                    <section className="InputSection_Description">
                      <section className="inputPanel">
                        {state !== 2 ? (
                          <>
                            <section
                              className="inputPanelHeader"
                              style={{ marginTop: '12px' }}
                            >
                              <Input
                                placeholder="Enter ETH Quantity"
                                label=""
                                type="number"
                                changeValue={setAmount}
                                value={tokenAmount}
                              />
                            </section>
                            <div className="LpBalance sub">
                              <a href="#max">
                                <p
                                  className="Text1 BuyMax"
                                  onClick={setMaxAmount}
                                >
                                  MAX
                                </p>
                              </a>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </section>
                    </section>
                    <>
                      {creating === false ? (
                        <>
                          {state === 2 && claimable === true ? (
                            <></>
                          ) : (
                            <button
                              className="claim-button"
                              disabled={state === 1 ? false : true}
                              onClick={onTokenDeposit}
                            >
                              {ButtonText}
                            </button>
                          )}

                          {depositUserAmount > 0 ? (
                            state === 1 ? (
                              <button
                                className="claim-button"
                                disabled={false}
                                onClick={onTokenEmergency}
                              >
                                Emergency Withdraw
                              </button>
                            ) : claimable === true ? (
                              <button
                                className="claim-button"
                                disabled={false}
                                onClick={onClaim}
                              >
                                Claim Token
                              </button>
                            ) : (
                              <></>
                            )
                          ) : (
                            <></>
                          )}
                          {depositUserAmount > 0 &&
                          state === 2 &&
                          refundable === true ? (
                            <button
                              className="claim-button"
                              disabled={false}
                              onClick={onWithdraw}
                            >
                              Withdraw your deposited ETH
                            </button>
                          ) : (
                            <></>
                          )}

                          {owner === address && (state === 1 || state === 2) ? (
                            <>
                              {claimable === false && refundable === false ? (
                                <button
                                  className="claim-button"
                                  disabled={claimable}
                                  onClick={onCancel}
                                >
                                  Cancel Presale
                                </button>
                              ) : (
                                <></>
                              )}
                              {refundable === false &&
                              state === 2 &&
                              claimable === false ? (
                                <button
                                  className="claim-button"
                                  disabled={
                                    refundable ||
                                    depositedAmount < softCap ||
                                    claimable ||
                                    state === 1
                                  }
                                  onClick={onFinalize}
                                >
                                  Finalize Presale
                                </button>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <>
                          <div className="loadingBox">
                            <p className="Text1" style={{ color: 'white' }}>
                              Loading...
                            </p>
                            <ClipLoader
                              color={'#afccc6'}
                              loading={creating}
                              size={30}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                          </div>
                        </>
                      )}
                    </>
                  </div>
                </div>
                {state === 2 ? (
                  <>
                    <div className="claim-card2">
                      <div className="commuity-progress-container">
                        <a
                          className="claim-button"
                          disabled={state === 1 ? false : true}
                          href={
                            `https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=` +
                            tokenAddress
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {'Buy ' + tokenName}
                        </a>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <br />
              </section>
            </div>
            <section className="ContactBox">
              <>
                <div className="claim-card">
                  <div className="launchpad-progress-container">
                    <div className="launchpad-progress-text">
                      Progress ({currentRaiseAmount.toFixed(2)}%)
                    </div>
                    <div className="launchpad-progress-bar">
                      <div
                        className="launchpad-progress-bar-filled"
                        style={{ width: `${progress}%` }}
                      />
                    </div>
                    <div className="launchpad-progress-endpoints">
                      <span className="launchpad-progress-text">
                        {depositedAmount.toFixed(3)} ETH
                      </span>
                      <span className="launchpad-progress-text">
                        {CapLimit} ETH
                      </span>
                    </div>
                  </div>
                </div>
                <br />
                <div className="claim-card">
                  <div className="token-info-item">
                    <span className="token-info-label">Sale Status</span>
                    <span className="token-info-value">
                      <div
                        className={`launchpad-status-text ${statusClasses[state]}`}
                        style={{ marginTop: '-4px' }}
                      >
                        <div className="launchpad-status-icon">
                          {statusIcons[state]}
                        </div>
                        <span className="launchpad-status-text">
                          {state === 1
                            ? 'Sale Live'
                            : state === 0
                            ? 'Upcoming'
                            : 'Closed'}
                        </span>
                      </div>
                    </span>
                  </div>
                  <hr />
                  <div className="token-info-item">
                    <span className="token-info-label">Max Buy</span>
                    <span className="token-info-value">{maxBuy} ETH</span>
                  </div>
                  <hr />
                  <div className="token-info-item">
                    <span className="token-info-label">Current Raise</span>
                    <span className="token-info-value">{currentRaise}</span>
                  </div>
                  <hr />
                  {depositUserAmount > 0 ? (
                    <>
                      <div className="token-info-item">
                        <span className="token-info-label">
                          Your Deposited Amount
                        </span>
                        <span className="token-info-value">
                          {depositUserAmount} ETH
                        </span>
                      </div>
                      <hr />
                    </>
                  ) : (
                    <></>
                  )}

                  {claimableAmount - withdrawAmount > 0 ? (
                    <>
                      <div className="token-info-item">
                        <span className="token-info-label">
                          Your Claimable Tokens
                        </span>
                        <span className="token-info-value">
                          {(claimableAmount - withdrawAmount).toFixed(0)}
                        </span>
                      </div>
                      <hr />
                    </>
                  ) : (
                    <></>
                  )}

                  {withdrawAmount > 0 ? (
                    <>
                      <div className="token-info-item">
                        <span className="token-info-label">
                          Your Withdrew Tokens
                        </span>
                        <span className="token-info-value">
                          {withdrawAmount.toFixed(0)}
                        </span>
                      </div>
                      <hr />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <br />
                <section>
                  <ClaimCard
                    status={state}
                    RugProof={true}
                    AllIn={allIns}
                    CapType={'Soft Cap'}
                    CapLimit={softCap} // ETH
                    tokenName={tokenName}
                    Logo={<img src={tokenLogo} className="claim-eth-logo" />}
                    about={tokenDescription}
                    tokenAddress={tokenAddress}
                    tokenSymbol={tokenSymbol}
                    tokenDecimals={18}
                    tokenTotalSupply={tokens}
                    tokenSupplySale={tokenSupply}
                    tokenSupplyLiquidity={liquidity}
                    tokenUnsoldTokens={'Burned 🔥'}
                    saleStarts={startTime}
                    saleEnds={endTime}
                    tokenCover={tokenBanner}
                    website={website}
                    telegram={telegram}
                    twitter={twitter}
                    discord={discord}
                  />
                </section>
              </>
            </section>
          </div>
        }
      </div>
      <Footer />
    </div>
  )
}

export default App
