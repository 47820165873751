/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import "../App.css";
import FairLaunchFactoryAbi from "../config/FairLaunchFactoryAbi.json";
import MultiCallAbi from "../config/MultiCallAbi.json";
import "../styles/MainContainer.css";
import { useWeb3Modal } from "@web3modal/react";
import LaunchpadCard from "../components/LaunchpadCard";
import ClipLoader from "react-spinners/ClipLoader";
import Footer from "../components/Footer";
import TopBar from "../components/TopBar";
import { ReactComponent as SearchIcon } from "../icons/search.svg";
import { createPublicClient, http } from "viem";
import { base } from "viem/chains";
import { Link } from "react-router-dom";

const publicClient = createPublicClient({
  chain: base,
  transport: http(),
});
const App = () => {
  const multicallAddress = "0xeeCa36fB4f5291810076bF1F23dd4d14b8AB364e";
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  let [loading, setLoading] = useState(false);
  const [presaleLists, setPresaleListDatas] = useState([]);
  const [firstConnect, setFirstConnect] = useState(false);
  const { open } = useWeb3Modal();
  const [currentLength, setCurrentLength] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const reloadWindow = async () => {
      try {
        window.location.reload();
      } catch (e) {
        console.error(e);
      }
    };
    if (isConnected === true && firstConnect === true) reloadWindow();
  }, [isConnected, firstConnect]);

  const { switchNetwork } = useSwitchNetwork();
  useEffect(() => {
    const switchChain = async () => {
      try {
        switchNetwork?.(8453);
      } catch (e) {
        console.error(e);
      }
    };
    if (isConnected === true) {
      if (chain?.id !== 8453) switchChain();
    }
  }, [isConnected, chain?.id, switchNetwork]);

  useEffect(() => {
    if (loading === true) {
      setTimeout(function () {
        setLoading(false);
      }, 3000);
    }
  }, [loading]);

  useEffect(() => {
    const FetchData = async () => {
      try {
        setLoading(true);
        const mainInfo = await publicClient.readContract({
          address: multicallAddress,
          abi: MultiCallAbi,
          functionName: "presaleInfo",
        });
        const otherInfo = await publicClient.readContract({
          address: multicallAddress,
          abi: MultiCallAbi,
          functionName: "presaleOtherInfo",
        });
        setTotalLength(Number(mainInfo[0].length));
        const length = Number(mainInfo[0].length);
        if (length > 0) {
          let presaleListsDatas = [];
          let presaleData;
          let last;
          if (length > 6) {
            last = length - 6;
            setCurrentLength(last);
          } else {
            last = 0;
          }
          for (let i = length - 1; i >= last; i--) {
            const isLive = mainInfo[0][i];
            let status;
            let presaleEndTimestamp = Number(mainInfo[1][i]);
            if (isLive === true) {
              status = 1;
            } else {
              if (Number(presaleEndTimestamp) < new Date().getTime() / 1000) {
                status = 2;
              } else {
                status = 0;
              }
            }
            let allIn = false;
            // const totalTokens = Number(getPresaleInfo[2])
            // if (Number(totalTokens) === 1000000000) {
            //   allIn = true
            // } else {
            //   allIn = false
            // }
            let softCap;
            const softCapAmount = Number(mainInfo[2][i]);
            softCap = Number(softCapAmount) / 10 ** 18;
            let progress;
            const totalDepositedBalance = Number(mainInfo[3][i]);
            progress =
              (Number(totalDepositedBalance) * 100) / Number(softCapAmount);
            let liquidity;
            const liquidityPercent = Number(otherInfo[3][i]);
            liquidity = Number(liquidityPercent);
            const presaleStartTime = Number(mainInfo[4][i]);
            let saleStart = Number(presaleStartTime);

            const name = otherInfo[0][i];
            let logoUrl = otherInfo[1][i];
            let bannerUrl = otherInfo[2][i];
            bannerUrl =
              "https://dexmaker.zapto.org:13001/uploads/" + bannerUrl;
            logoUrl =
              "https://dexmaker.zapto.org:13001/uploads/" + logoUrl;
            presaleData = {
              state: status,
              AllIn: allIn,
              CapLimit: softCap,
              progress: progress,
              Liquidity: liquidity,
              saleStarts: saleStart,
              saleEnds: presaleEndTimestamp,
              tokenName: name,
              logoUrl: logoUrl,
              bannerUrl: bannerUrl,
              address: otherInfo[4][i],
              depositedAmount: Number(totalDepositedBalance) / 10 ** 18,
            };
            presaleListsDatas.push(presaleData);
          }
          setPresaleListDatas(presaleListsDatas);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    };
    FetchData();
  }, [chain?.id]);

  const loadMoreLists = () => async () => {
    try {
      setLoading(true);
      const length = totalLength;
      if (length > 0) {
        let presaleListsDatas = [];
        let presaleData;
        let last;
        if (currentLength > 0) {
          if (currentLength > 6) {
            last = currentLength - 6;
          } else {
            last = 0;
          }
          setCurrentLength(last);
          for (let i = currentLength - 1; i >= last; i--) {
            const isLive = mainInfo[0][i];
            let status;
            let presaleEndTimestamp = Number(mainInfo[1][i]);
            if (isLive === true) {
              status = 1;
            } else {
              if (Number(presaleEndTimestamp) < new Date().getTime() / 1000) {
                status = 2;
              } else {
                status = 0;
              }
            }
            let allIn = true;
            let softCap;
            const softCapAmount = Number(mainInfo[2][i]);
            softCap = Number(softCapAmount) / 10 ** 18;
            let progress;
            const totalDepositedBalance = Number(mainInfo[3][i]);
            progress =
              (Number(totalDepositedBalance) * 100) / Number(softCapAmount);
            let liquidity;
            const liquidityPercent = Number(otherInfo[3][i]);
            liquidity = Number(liquidityPercent);
            const presaleStartTime = Number(mainInfo[4][i]);
            let saleStart = Number(presaleStartTime);

            const name = otherInfo[0][i];
            let logoUrl = otherInfo[1][i];
            let bannerUrl = otherInfo[2][i];
            bannerUrl =
              "https://dexmaker.zapto.org:13001/uploads/" + bannerUrl;
            logoUrl =
              "https://dexmaker.zapto.org:13001/uploads/" + logoUrl;
            presaleData = {
              state: status,
              AllIn: allIn,
              CapLimit: softCap,
              progress: progress,
              Liquidity: liquidity,
              saleStarts: saleStart,
              saleEnds: presaleEndTimestamp,
              tokenName: name,
              logoUrl: logoUrl,
              bannerUrl: bannerUrl,
              address: otherInfo[4][i],
              depositedAmount: Number(totalDepositedBalance) / 10 ** 18,
            };
            presaleListsDatas.push(presaleData);
          }
          let newDatas = presaleLists.concat(presaleListsDatas);
          setPresaleListDatas(newDatas);
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const launchTypes = ['All Launches', 'Fair Launches', 'Stealth Launches']
  const statusFilters = ['Status', 'Upcoming', 'Closed', 'Live']
  const [statusFilter, setStatusFilter] = useState('Status')
  const history = useHistory()

  const handleLaunchTypeChange = type => {
    const paths = {
      'all launches': '/AllLaunches',
      'fair launches': '/FairLaunches',
      'stealth launches': '/CommunityFunded',
      'my contributions': '/MyContributions'
    }

    const path = paths[type.toLowerCase()]
    if (path) {
      history.push(path)
    }
  }

  const handleStatusFilterChange = (status) => {
    setStatusFilter(status);
  };

  const shouldRenderCard = (item) => {
    if (statusFilter.toLocaleLowerCase() === "status") {
      return true;
    } else if (statusFilter.toLocaleLowerCase() === "upcoming" && item.state === 0) {
      return true;
    } else if (statusFilter.toLocaleLowerCase() === "live" && item.state === 1) {
      return true;
    } else if (statusFilter.toLocaleLowerCase() === "closed" && item.state === 2) {
      return true;
    } else if (statusFilter.toLocaleLowerCase() === "sale live" && item.state === 3) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <div className="GlobalContainer">
        <div style={{ zIndex: 1 }}>
          <TopBar />
          <div className="navBar"></div>
          <h1 className="h1 title center">
              Fair Launches
            </h1>
          <div className="headerMargin" />
          <div className="MainDashboard">
            <>
              <section className="FairLaunchFlexLayout">
                <section className="">
                  <p style={{ textAlign: "center" }}>
                    <Link to="/FairLaunch" className="create-token-button">
                      Create&nbsp;Fair&nbsp;Launch
                    </Link>
                  </p>
                  <br />
                  {/* Search field */}
                  <section>
                    <div className="filter-row-offset">
                      <div className="filter-row">
                        <div className="filter-buttons-row">
                          <div className="filter-dropdown-row">
                            <select
                              className="launch-type-dropdown"
                              styles={{
                                control: (styles) => ({
                                  ...styles,
                                  backgroundColor: "#06021d",
                                  color: "white",
                                }),
                                option: (styles) => ({
                                  ...styles,
                                  backgroundColor: "#3554A2",
                                  color: "white",
                                }),
                                singleValue: (styles) => ({
                                  ...styles,
                                  color: "white",
                                }),
                                input: (styles) => ({
                                  ...styles,
                                  caretColor: "transparent",
                                }),
                                dropdownIndicator: (styles) => ({
                                  ...styles,
                                  color: "9900c8",
                                }),
                                placeholder: (styles) => ({
                                  gridArea: "1 / 1 / 2 / 3",
                                  color: "#777777",
                                  marginLeft: "2px",
                                  marginRight: "2px",
                                  boxSizing: "border-box",
                                }),
                              }}
                              onChange={(e) =>
                                handleLaunchTypeChange(e.target.value)
                              }
                            >
                              {launchTypes.map((type, index) => (
                                <option key={index} value={type.toLowerCase()}>
                                  {type}
                                </option>
                              ))}
                            </select>
                            <select
                              className="status-filter-dropdown"
                              onChange={(e) =>
                                handleStatusFilterChange(e.target.value)
                              }
                            >
                              {statusFilters.map((status, index) => (
                                <option
                                  key={index}
                                  value={status.toLowerCase()}
                                >
                                  {status}
                                </option>
                              ))}
                            </select>
                          </div>
                          <Link
                            to="/MyContributions"
                            className="my-contributions-button"
                          >
                            My&nbsp;Contributions
                          </Link>
                        </div>
                        <div className="search-field">
                          <SearchIcon className="search-field-icon" />
                          <input
                            className="searchField search-field-input"
                            type="text"
                            placeholder="Search token"
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                        </div>
                      </div>
                    </div>
                  </section>

                  <br />
                  {presaleLists.length > 0 ? (
                    <>
                      <div className="launchpad-card-grid">
                        {presaleLists
                          .filter((item) => shouldRenderCard(item))
                          .filter((data) =>
                            data.tokenName
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase())
                          )
                          .map(
                            (
                              {
                                state,
                                AllIn,
                                CapLimit,
                                progress,
                                Liquidity,
                                saleStarts,
                                saleEnds,
                                tokenName,
                                logoUrl,
                                bannerUrl,
                                address,
                                depositedAmount,
                              },
                              i
                            ) => (
                              <LaunchpadCard
                                status={state}
                                RugProof={true}
                                AllIn={AllIn}
                                CapType="Minimum Raise"
                                CapLimit={CapLimit}
                                progress={progress}
                                Liquidity={Liquidity}
                                lockupTime="Automatic Burn 🔥"
                                saleStarts={saleStarts}
                                saleEnds={saleEnds}
                                tokenName={tokenName}
                                Logo={
                                  <img
                                    src={logoUrl}
                                    className="claim-eth-logo"
                                  />
                                }
                                Banner={bannerUrl}
                                presaleAddress={address}
                                depositedAmount={depositedAmount}
                              />
                            )
                          )}
                      </div>
                      {loading === true ? (
                        <div className="loadingBox">
                          <ClipLoader
                            color={"#afccc6"}
                            loading={true}
                            size={50}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          />
                        </div>
                      ) : currentLength > 0 ? (
                        <p className="loadMoreText" onClick={loadMoreLists()}>
                          Load more ...
                        </p>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : totalLength === 0 ? (
                    <div className="loadingBox">
                      <p className="Text1" style={{ color: "gray" }}>
                        No data yet
                      </p>
                    </div>
                  ) : (
                    <div className="EmptyLaunchpad">
                      <div className="loadingBox">
                        <p className="Text1" style={{ color: "gray" }}>
                          Loading...
                        </p>
                        <ClipLoader
                          color={"#afccc6"}
                          loading={true}
                          size={50}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    </div>
                  )}
                  <br />
                  <br />
                  <br />
                  <br />
                </section>
              </section>
            </>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
