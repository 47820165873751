/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'
import '../App.css'
import TokenAbi from '../config/TokenAbi.json'
import '../styles/MainContainer.css'
import Footer from '../components/Footer'
import Web3 from 'web3'
import TokenCard from '../components/TokenCard.jsx'
import TopBar from '../components/TopBar'
import { useQueryParam, StringParam } from 'use-query-params'
import { createPublicClient, http } from 'viem'
import { base } from 'viem/chains'
import { writeContract, readContract, waitForTransaction } from '@wagmi/core'
import ClipLoader from 'react-spinners/ClipLoader'
import MaxInput from '../components/MaxInput.tsx'
import routerAbi from '../config/UniswapRouterAbi.json'
import ETHIcon from '../icons/eth.png'
import { toast } from 'react-hot-toast'

const publicClient = createPublicClient({
  chain: base,
  transport: http()
})

const App = () => {
  let [coinAddress] = useQueryParam('address', StringParam)
  let routerAddress = '0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24'
  let WETHAddress = '0x4200000000000000000000000000000000000006'
  const BASE_PROVIDER_URL = 'https://base-pokt.nodies.app'
  let BaseWeb3 = new Web3(new Web3.providers.HttpProvider(BASE_PROVIDER_URL))
  const { address, isConnected } = useAccount()
  const { chain } = useNetwork()
  const [tokenName, setTokenName] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [tokenAddress, setTokenAddress] = useState('')
  const [tokenLogo, setTokenLogo] = useState('')
  const [tokenBanner, setTokenBanner] = useState('')
  const [tokenSupply, setTokenSupply] = useState(0)
  const [liquidity, setLiquidity] = useState(0)
  const [tokenDescription, setTokenDescription] = useState('')
  const [state, setState] = useState()
  const [tokenDecimal, setTokenDecimal] = useState('')
  let [loading, setLoading] = useState(false)
  const [firstConnect, setFirstConnect] = useState(false)
  const [burnAmount, setBurnAmount] = useState(0)

  const [website, setWebsite] = useState('')
  const [telegram, setTelegram] = useState('')
  const [discord, setDiscord] = useState('')
  const [twitter, setTwitter] = useState('')

  let [inputBalance, setInputBalance] = useState(0)
  let [tokenBalance, setTokenBalance] = useState(0)
  let [tokenAllowance, setTokenAllowance] = useState(0)
  const [tokenAmount, setAmount] = useState(0)
  let [creating, setCreating] = useState(false)
  let [accountBalance, setAccountBalance] = useState(0)
  const [inputToken, setInputToken] = useState('ETH')
  const [tokenOutAmount, setTokenOutAmount] = useState(0)

  useEffect(() => {
    const reloadWindow = async () => {
      try {
        window.location.reload()
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true && firstConnect === true) reloadWindow()
  }, [isConnected, firstConnect])

  const { switchNetwork } = useSwitchNetwork()
  useEffect(() => {
    const switchChain = async () => {
      try {
        switchNetwork?.(8453)
      } catch (e) {
        console.error(e)
      }
    }
    if (isConnected === true) {
      if (chain?.id !== 8453) switchChain()
    }
  }, [isConnected, chain?.id, switchNetwork])

  useEffect(() => {
    if (loading === true) {
      setTimeout(function () {
        setLoading(false)
      }, 3000)
    }
  }, [loading])

  useEffect(() => {
    const FetchData = async () => {
      try {
        const TokenInfo = await publicClient.readContract({
          address: coinAddress,
          abi: TokenAbi,
          functionName: 'getCoinInfo'
        })
        console.log('===== INFO =====')
        console.log(TokenInfo)

        setTokenName(TokenInfo[0])
        setTokenSymbol(TokenInfo[1])
        setTokenAddress(coinAddress)
        setTokenLogo(
          'https://dexmaker.zapto.org:13001/uploads/' + TokenInfo[5]
        )
        setTokenBanner(
          'https://dexmaker.zapto.org:13001/uploads/' + TokenInfo[6]
        )

        setTokenDecimal(Number(TokenInfo[3]))
        setTokenSupply(Number(TokenInfo[2]) / 10 ** Number(TokenInfo[3]))
        setLiquidity(Number(TokenInfo[4]) / 10 ** 18)
        setBurnAmount(Number(TokenInfo[8]) / 10 ** 18)
        setWebsite(TokenInfo[10][0])
        setTelegram(TokenInfo[10][1])
        setDiscord(TokenInfo[10][2])
        setTwitter(TokenInfo[10][3])
        let status = 0
        if (Number(TokenInfo[4]) / 10 ** 18 > 0) {
          status = 1
        }
        setState(status)

        const CoinDescription = await publicClient.readContract({
          address: coinAddress,
          abi: TokenAbi,
          functionName: '_description'
        })
        setTokenDescription(CoinDescription)
        if (address) {
          let accountBalance = await BaseWeb3.eth.getBalance(address)
          accountBalance = BaseWeb3.utils.fromWei(accountBalance, 'ether')
          setAccountBalance(accountBalance)
          if (inputToken === 'ETH') {
            setInputBalance(accountBalance)
          } else {
            setInputBalance(tokenBalance)
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
    FetchData()
  }, [chain?.id, coinAddress])

  useEffect(() => {
    const FetchAmount = async () => {
      try {
        let amounts
        amounts = await readContract({
          address: tokenAddress,
          abi: TokenAbi,
          functionName: 'balanceOf',
          args: [address]
        })
        let allowance
        allowance = await readContract({
          address: tokenAddress,
          abi: TokenAbi,
          functionName: 'allowance',
          args: [address, routerAddress]
        })
        setTokenAllowance(Number(allowance) / 10 ** 18)
        setTokenBalance(BaseWeb3.utils.fromWei(String(amounts), 'ether'))
      } catch (e) {
        console.error(e)
      }
    }
    if (address && tokenAddress) {
      FetchAmount()
    }
  }, [tokenAddress, address, creating])

  const chanageCurrency = async () => {
    if (inputToken === 'ETH') {
      setInputToken('Token')
      setInputBalance(tokenBalance)
      setAmount(tokenOutAmount)
    } else {
      setInputToken('ETH')
      setInputBalance(accountBalance)
      setAmount(tokenOutAmount)
    }
  }

  const setMaxAmount = async () => {
    if (accountBalance > 0) accountBalance = accountBalance - 0.0001
    if (inputToken === 'ETH') {
      setAmount(accountBalance)
    } else {
      setAmount(tokenBalance)
    }
  }

  useEffect(() => {
    const FetchAmount = async () => {
      try {
        let amounts
        let path
        if (inputToken === 'ETH') {
          path = [WETHAddress, tokenAddress]
          amounts = await publicClient.readContract({
            address: routerAddress,
            abi: routerAbi,
            functionName: 'getAmountsOut',
            args: [BaseWeb3.utils.toWei(String(tokenAmount), 'ether'), path]
          })
        } else {
          path = [tokenAddress, WETHAddress]
          amounts = await publicClient.readContract({
            address: routerAddress,
            abi: routerAbi,
            functionName: 'getAmountsOut',
            args: [BaseWeb3.utils.toWei(String(tokenAmount), 'ether'), path]
          })
        }
        setTokenOutAmount(Number(amounts[1]) / 10 ** 18)
      } catch (e) {
        console.error(e)
      }
    }
    if (Number(tokenAmount)) {
      FetchAmount()
    } else {
      setTokenOutAmount(0)
    }
  }, [inputToken, tokenAmount, creating])

  const onTokenSwap = async () => {
    try {
      setCreating(true)
      let swap
      if (inputToken === 'ETH') {
        const path = [WETHAddress, tokenAddress]
        const timestamp = new Date().getTime() / 1000 + 300
        swap = await writeContract({
          address: routerAddress,
          abi: routerAbi,
          functionName: 'swapExactETHForTokensSupportingFeeOnTransferTokens',
          value: BaseWeb3.utils.toWei(String(tokenAmount), 'ether'),
          args: [0, path, address, timestamp.toFixed(0)]
        })
        await waitForTransaction({
          hash: swap.hash
        })
      } else {
        if (tokenAllowance > 0) {
          const path = [tokenAddress, WETHAddress]
          const timestamp = new Date().getTime() / 1000 + 300
          swap = await writeContract({
            address: routerAddress,
            abi: routerAbi,
            functionName: 'swapExactTokensForETHSupportingFeeOnTransferTokens',
            args: [
              BaseWeb3.utils.toWei(String(tokenAmount), 'ether'),
              0,
              path,
              address,
              timestamp.toFixed(0)
            ]
          })
          await waitForTransaction({
            hash: swap.hash
          })
        } else {
          let max =
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
          let approve
          approve = await writeContract({
            address: tokenAddress,
            abi: TokenAbi,
            functionName: 'approve',
            args: [routerAddress, max]
          })
          await waitForTransaction({
            hash: approve.hash
          })
        }
        setInputToken(inputToken)
      }
      setTimeout(function () {
        setCreating(false)
      }, 3000)
      toast.success(`Successfully ${tokenAmount} ETH swapped`)
    } catch (err) {
      toast.error('There is a problem with your Swap. Please try again later')
      setCreating(false)
    }
  }

  // const UNISWAP_TOKEN_LIST = 'https://ipfs.io/ipns/tokens.uniswap.org'

  // Use the native token of the connected chain as the default input token
  // const NATIVE = 'NATIVE' // Special address for native token

  return (
    <div>
      <div className="GlobalContainer">
        {
          <div style={{ zIndex: 1 }}>
            <TopBar />
            <div className="navBar"></div>
            <h1 className="h1 title center">Token Details</h1>
            <br />
            <br />
            <div className="headerMargin" />
            <div className="MainDashboard ResponsiveFlexLayout">
              <div>
                <iframe
                  src={`https://dexscreener.com/base/${tokenAddress}?embed=1&trades=0&info=0&theme=light`}
                  className="chart"
                  title="chart"
                />
              </div>
              <section className="ClaimLeftColumn">
                <p className="avoid-scam avoid-scam-text">
                  Avoid scam links! Make sure the website is dexmaker.io
                </p>
                <div className="swap-card">
                  <p
                    className="claim-text"
                    style={{
                      fontSize: '20px',
                      margin: 'auto',
                      width: '100%',
                      textAlign: 'center'
                    }}
                  >
                    Swap
                  </p>
                  <div className="launchpad-progress-container">
                    <div className="launchpad-progress-endpoints">
                      <span className="launchpad-progress-text"></span>
                      <span className="launchpad-progress-text">
                        Balance: &nbsp;
                        {Number(inputBalance).toFixed(2)}{' '}
                        {inputToken === 'ETH' ? 'ETH' : tokenName}
                      </span>
                    </div>
                    <section className="InputSection_Description">
                      <section className="inputPanel">
                        <section
                          className="inputPanelHeader"
                          style={{
                            marginTop: '12px'
                          }}
                        >
                          <div className="InputContainer">
                            <MaxInput
                              placeholder="Enter Amount"
                              label=""
                              type="number"
                              changeValue={setAmount}
                              value={tokenAmount}
                            />
                          </div>
                        </section>
                        <div className="LpBalance sub">
                          <img
                            src={inputToken === 'ETH' ? ETHIcon : tokenLogo}
                            className="claim-eth-token-logo"
                          />

                          <p className="Text1 BuyMax" onClick={setMaxAmount}>
                            MAX
                          </p>
                        </div>
                      </section>
                    </section>
                  </div>
                  <button onClick={chanageCurrency} className="claim-button">
                    ↑ ↓
                  </button>
                  <div className="launchpad-progress-container">
                    <section className="InputSection_Description">
                      <section className="inputPanel">
                        <section
                          className="inputPanelHeader"
                          style={{
                            marginTop: '12px'
                          }}
                        >
                          <div className="InputContainer">
                            <input
                              placeholder=""
                              label=""
                              type="number"
                              value={tokenOutAmount}
                              disabled
                            />
                          </div>
                        </section>
                        <div className="LpBalance sub">
                          <img
                            src={inputToken !== 'ETH' ? ETHIcon : tokenLogo}
                            className="claim-eth-token-logo"
                          />
                        </div>
                      </section>
                    </section>
                  </div>
                  {creating === false ? (
                    <button onClick={onTokenSwap} className="claim-button">
                      {inputToken !== 'ETH' && tokenAllowance === 0
                        ? 'Approve token First'
                        : 'Swap Tokens'}
                    </button>
                  ) : (
                    <>
                      <div className="loadingBox">
                        <p className="Text1" style={{ color: 'black' }}>
                          Loading...
                        </p>
                        <ClipLoader
                          color={'#afccc6'}
                          loading={creating}
                          size={30}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    </>
                  )}

                  <div
                    className="token-info-item"
                    style={{ marginTop: '10px' }}
                  >
                    {/* <span className="token-info-label">Current Price</span>
                    <span className="token-info-value">$ {tokenPrice}</span> */}
                  </div>
                </div>
                {/* <div className="claim-card2">
                  <div className="commuity-progress-container">
                    <a
                      className="claim-button"
                      disabled={state === 1 ? false : true}
                      href={
                        `https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=` +
                        tokenAddress
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {'Claim $' + tokenName.toUpperCase()}
                    </a>
                  </div>
                </div> */}
              </section>
            </div>
            <br />
            <br />
            <section className="ContactBox">
              <>
                <section>
                  <TokenCard
                    status={state}
                    RugProof={burnAmount > 0 ? true : false}
                    // AllIn={allIns}
                    tokenName={tokenName}
                    Logo={<img src={tokenLogo} className="claim-eth-logo" />}
                    about={tokenDescription}
                    tokenAddress={tokenAddress}
                    tokenSymbol={tokenSymbol}
                    tokenDecimals={tokenDecimal}
                    tokenTotalSupply={tokenSupply}
                    tokenSupplyLiquidity={liquidity}
                    tokenUnsoldTokens={'Burned 🔥'}
                    tokenCover={tokenBanner}
                    website={website}
                    telegram={telegram}
                    twitter={twitter}
                    discord={discord}
                  />
                </section>
              </>
            </section>
          </div>
        }
      </div>
      <Footer />
    </div>
  )
}

export default App
