import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as StatusSaleLive } from '../icons/status-sale-live.svg'
import { ReactComponent as StatusSaleClosed } from '../icons/status-closed.svg'
import { ReactComponent as StatusUpcoming } from '../icons/status-upcoming.svg'
import Countdown from './Countdown'
import { Link } from 'react-router-dom'

const LaunchpadCard = ({
    status,
    RugProof,
    AllIn,
    // Doxed,
    // KYC,
    CapType,
    CapLimit,
    progress,
    Liquidity,
    lockupTime,
    saleStarts,
    saleEnds,
    tokenName,
    Logo,
    Banner,
    presaleAddress,
    depositedAmount
}) => {
    const statusIcons = {
        0: <StatusUpcoming />,
        1: <StatusSaleLive />,
        2: <StatusSaleClosed />
    }

    const statusClasses = {
        0: 'launchpad-status-upcoming',
        1: 'launchpad-status-sale-live',
        2: 'launchpad-status-closed'
    }

    const badges = [
        {
            name: 'Rug-Proof',
            className: 'launchpad-badge-rug-proof',
            condition: RugProof
        },
        {
            name: 'All-In',
            className: 'launchpad-badge-all-in',
            condition: AllIn
        }
        // { name: 'Doxed', className: 'launchpad-badge-doxed', condition: Doxed },
        // { name: 'KYC', className: 'launchpad-badge-kyc', condition: KYC }
    ]

    const link = `/buy/?address=${presaleAddress}`
    const progressText = progress
    if (progress > 100) {
        progress = 100
    }
    const bannerUrl = 'url("' + Banner + '")'
    return (
        <div className="launchpad-card">
            <div
                style={{
                    backgroundImage: bannerUrl,
                    backgroundSize: '100% 120px',
                    backgroundRepeat: 'no-repeat'
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div
                        className={`launchpad-status-text ${statusClasses[status]}`}
                    >
                        <div className="launchpad-status-icon">
                            {statusIcons[status]}
                        </div>
                        <span className="launchpad-status-text">
                            {status === 1
                                ? 'Sale Live'
                                : status === 0
                                ? 'Upcoming'
                                : 'Closed'}
                        </span>
                    </div>
                </div>

                <div
                    className="launchpad-eth-logo-container"
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    {Logo}
                </div>

                <p className="launchpad-token-name">{tokenName}</p>

                <p className="launchpda-cap-type">{CapType}</p>

                <p className="lauchpad-cap-limit">{CapLimit} ETH</p>

                <div className="launchpad-progress-container">
                    <div className="launchpad-progress-text">
                        Progress ({progressText.toFixed(3)}%)
                    </div>
                    <div className="launchpad-progress-bar">
                        <div
                            className="launchpad-progress-bar-filled"
                            style={{ width: `${progress}%` }}
                        />
                    </div>
                    <div className="launchpad-progress-endpoints">
                        <span className="launchpad-progress-text">
                            {depositedAmount.toFixed(3)} ETH
                        </span>
                        <span className="launchpad-progress-text">
                            {CapLimit} ETH
                        </span>
                    </div>
                </div>
                {/*<p className="launchpad-progress-text">Liquidity ({Liquidity}%)</p>
      <div className="launchpad-lockup-time-row">
        <span className="launchpad-lockup-time-text">Lockup Time</span>
        <span className="launchpad-liquidity-text">{lockupTime}</span>
      </div>*/}
                <br />
                <div
                    className="launchpad-badges-row"
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center'
                    }}
                >
                    {badges.map((badge, index) => (
                        <div
                            key={index}
                            className={badge.className}
                            style={{
                                display: badge.condition ? 'flex' : 'none'
                            }}
                        >
                            <span className="launchpad-badge-text">
                                {badge.name}
                            </span>
                        </div>
                    ))}
                </div>

                <div className="launchpad-hr">
                    <hr />
                </div>

                <div className="launchpad-bottom-row">
                    <div className="launchpad-sale-starts-container">
                        <div className="launchpad-sale-starts-text">
                            {status === 1
                                ? 'Sale Ends : '
                                : status !== 2
                                ? 'Sale Starts : '
                                : 'Sale Ended'}
                        </div>
                        <div className="launchpad-sale-starts-timer">
                            {status !== 2 ? (
                                <Countdown
                                    futureDate={
                                        status === 1 ? saleEnds : saleStarts
                                    }
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>

                    <Link className="launchpad-details-button" to={link}>
                        <span className="launchpad-details-button-text">
                            Details
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    )
}

LaunchpadCard.propTypes = {
    RugProof: PropTypes.bool,
    AllIn: PropTypes.bool,
    // Doxed: PropTypes.bool,
    // KYC: PropTypes.bool,
    CapType: PropTypes.string.isRequired,
    CapLimit: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    Liquidity: PropTypes.number.isRequired,
    lockupTime: PropTypes.string.isRequired,
    saleStarts: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    saleEnds: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    tokenName: PropTypes.string.isRequired,
    Logo: PropTypes.element.isRequired,
    presaleAddress: PropTypes.element.isRequired,
    depositedAmount: PropTypes.element.isRequired
}

LaunchpadCard.defaultProps = {
    RugProof: false,
    AllIn: false
    // Doxed: false,
    // KYC: false
}

export default LaunchpadCard
